<template>
	<div class="container" ref="reqDetail">
		<el-page-header @back="goBack" content="详情页面">
		</el-page-header>
		<div class="conts" v-if="reqInfo">
			<div class="">
				<div class="cons_left">
					<div class="carousel-warp">
						<template v-if="reqInfo.attachlist != undefined && reqInfo.attachlist.length > 0">
							<template v-if="$util.isVideo(reqInfo.attachlist[0].sdra_filePath)">
								<video :src="reqInfo.attachlist[0].sdra_filePath" controls="controls" loop="loop" 
									:id="reqInfo.attachlist[0].sdra_fileid" @play="play(reqInfo.attachlist[0].sdra_fileid)"></video>
							</template>
							<template v-else>
								<!-- <el-carousel :interval="5000" arrow="always">
									<el-carousel-item v-for="imageInfo in reqInfo.attachlist" :key="imageInfo.sdra_fileid">
										<div class="imagewarp">
											<img :src="baseUrlpath+imageInfo.sdra_filePath" @error="imgOnError" />
										</div>
									</el-carousel-item>
								</el-carousel> -->
								<detailSwiper :attachlist="reqInfo.attachlist" :baseUrlpath="baseUrlpath"></detailSwiper>
							</template>
						</template>
						<template v-else>
							<img src="ss" @error="imgOnError" />
						</template>
					</div>
					<div class="flex-start-start">
						<div style="width: 40%;">
							<div class="flex-between person_warp">
								<div @click="showPerCenter">
									<personInfo :personId="reqInfo.sdrr_userId"></personInfo>
								</div>
								<div class="btn_warp" v-if="reqInfo.isSelf == '0'">
									<div class="btn" @click="haveTalk()">聊一聊</div>
								</div>
							</div>
							<div class="b-warp">
								<div class="bw-r">
									<div style="margin-bottom: 16px;">
										<div class="flex-start lev-msg-tab-warp">
											<div class="tab-item" :class="{'active':tabindex == '1'}" @click="tabindex = '1'">留言</div>
										</div>
									</div>
									<div v-if="tabindex =='1'">
										<pinglun :reqNo="reqNo" :baseUrlpath="baseUrlpath"></pinglun>
									</div>
								</div>
							</div>
						</div>

						<div class="tb-wrap">
							<div class="ri-body">
								<div class="con_area">
									<div class="rib-head">
										<div class="flex-between hundred">
											<div class="flex-start">
											<!-- 	<div class="mrgr">{{$util.getDisPlay(reqInfo.sdrr_recommendType,$util.recommendLebels)}}</div>
												<div class="point mrgr">|</div> -->
												<div class="mrgr">{{reqInfo.sdrr_recommendTitle}}</div>
											</div>
										</div>
									</div>
									<div class="flex-column-start">
										<div class="flex-start-start margt16">
											<div>地址：</div>
											<div class="flexone">{{reqInfo.sdrr_recommendPlace}}</div>
										</div>
									</div>
									<div class="rib-nail" v-if="reqInfo.sdrr_contents.length > 0" v-html="$util.formateContentRevise(reqInfo.sdrr_contents)"></div>
								</div>
								<div class="con_area" v-if="isShowConcact">
									<pickContact ref="pickContact" :queryUid="reqInfo.sdrr_userId" :baseUrlpath="baseUrlpath" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
								</div>
							</div>
							<div class="ri_foot" v-if="reqInfo.isSelf == '1'">
								<div class="btn_warp">
									<el-popover placement="left" width="200" trigger="click" popper-class="manager_btn_popper">
										<div class="pop_btn_warp">
											<div :class="[isStickTop?'active':'item_btn']" v-if="isStickTop">已置顶</div>
											<div class="item_btn" v-else @click="stickTopReq()">置顶</div>
											<div class="item_btn" @click="modifyReq()">编辑</div>
											<div class="item_btn" @click="putDownReq()">删除</div>
										</div>
										<div class="nomal_btn" slot="reference">管理</div>
									</el-popover>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="pers-drawer">
			<el-drawer title="" size="40%" :visible="percenterdrawer" :with-header="false" @close="handledrawerClose"
				direction="rtl">
				<customCenter ref="pct" :personId="reqInfo.ssr_userId"></customCenter>
			</el-drawer>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	import pinglun from '@/components/pinglun.vue'
	import chujia from '@/components/chujia.vue'
	import pickContact from '@/components/pickContact.vue'
	import QRCode from 'qrcodejs2'
	import customCenter from '@/components/customCenter.vue'
	import personInfo from '@/components/personInfo.vue'
	import detailSwiper from '@/components/swiper/detailSwiper.vue'
	export default {
		components: {
			pinglun,
			chujia,
			pickContact,
			customCenter,
			personInfo,
			detailSwiper
		},
		data() {
			return {
				baseUrlpath: '',
				initparams: {},
				reqInfo: {},
				activeName: 'first',
				comments: [],
				offers: [],
				reqNo: '',
				tabindex: '1',
				qrcodeWx: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				wxPayQRcode: false,
				zfbPayQRcode: false,
				defalutImg: require('@/assets/icon/noimg.jpg'),
				ispay: false,
				wxqrcode: null,
				checkInterval: null,
				wxQrcoswloading: true,
				isStickTop: false,
				isShowConcact:false,
				isDetail:true,
				percenterdrawer:false,
				inchina:''
			}
		},
		created() {
			// console.log(this.$route.query);
			this.initparams = this.$route.query;
			this.baseUrlpath = this.baseUrl;
			this.reqNo = this.initparams.reqNo;
			this.inchina = localStorage.getItem('inChina');
			if(this.inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
			this.getReqDetailInfo();
		},
		mounted() {
			window.scrollTo(0, 0)
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods: {
			showPerCenter() {
				this.percenterdrawer = true
			},
			handledrawerClose() {
				this.percenterdrawer = false
			},
			play(fileId){
				const videoElements = document.getElementsByTagName('video');
				console.log('页面的video数量====>'+videoElements.length)
				if (videoElements && videoElements.length > 0) {
					for (let i = 0; i < videoElements.length; i++) {
						if (fileId === videoElements[i].id) {
							videoElements[i].play()
						} else {
							videoElements[i].pause()
						}
					}
				}
			},
			haveTalk() {
				var accesstoken = localStorage.getItem('accesstoken');
				if (!accesstoken) {
					this.$util.warnMsg('请先登录系统');
					return false
				}
				var url = 'https://www.fayanbo.com/chat/#/message?token=' + accesstoken + '&fayanboId=' + this.userInfo
					.fayanboId + '&tId=' + this.reqInfo.ssr_userId;
				this.openWindow(url, '我的消息', 1000, 1000)
			},
			openWindow(url, title, w, h) {
				const dualScreenLeft =
					window.screenLeft !== undefined ? window.screenLeft : screen.left;
				const dualScreenTop =
					window.screenTop !== undefined ? window.screenTop : screen.top;

				const width = window.innerWidth ?
					window.innerWidth :
					document.documentElement.clientWidth ?
					document.documentElement.clientWidth :
					screen.width;
				const height = window.innerHeight ?
					window.innerHeight :
					document.documentElement.clientHeight ?
					document.documentElement.clientHeight :
					screen.height;

				const left = width / 2 - w / 2 + dualScreenLeft;
				const top = height / 2 - h / 2 + dualScreenTop;
				const newWindow = window.open(
					url,
					title,
					"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
					//"toolbar=yes, location=yes, directories=yes, status=yes, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=yes, width=" +
					w +
					", height=" +
					h +
					", top=" +
					top +
					", left=" +
					left
				);

				if (window.focus) {
					newWindow.focus();
				}
				
				newWindow.location.reload(true)
			},
			getReqDetailInfo() {
				var vm = this;
				var params = {};
				params.reqNo = vm.initparams.reqNo;
				var requestUrl = '/apis/getRecommendReqInfo'
				if(this.inchina == '1'){
					requestUrl = '/cnapis/getRecommendReqInfo'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.reqInfo = res.reqInfo
					vm.isShowConcact = res.reqInfo.sdrr_isShowConcact == '1'?true:false
					vm.isStickTop = res.reqInfo.isStickTop
				});
			},
			goBack() {
				this.$util.goBack();
				//this.$router.go(-1)
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			
			putDownReq(){
				var _this = this;
				this.$confirm('确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.soldOut();
				}).catch(() => {
				
				});
			},
			async soldOut(){
				var _this = this;
				const initres = await queryUrl('/apis/delReqCommend',{sdr_reqId:this.reqNo}); 
				if(initres.ec == '0'){
					this.$util.msg("删除成功",{},()=>{
						_this.$util.goBack();
					});
				}else{
					this.$util.msg(initres.msg)
				}
			},
			async stickTopReq() {
				if (this.isStickTop) {
					return;
				}
				var needCost = 0;
				var _this = this;
				const initres = await queryUrl('/apis/queryJdNeedCost',{queryType: '11'});
				if (initres.ec == '0') {
					needCost = initres.jdCost;
				}
				this.$confirm('置顶需花费' + needCost + '金豆', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.dealStickTopReq();
				}).catch(() => {

				});
			},
			async dealStickTopReq() {
				const initres = await queryUrl('/apis/dealReqRecomStickTop',{reqNo: this.reqNo})
				if (initres.ec == '0') {
					this.isStickTop = true;
					this.$util.msg('置顶成功');
				} else {
					var msg = initres.msg;
					this.$util.msg(msg);
				}
			},
			modifyReq(){
				this.$router.push({
				  name: '/recommend/modify',
				  query: {reqNo:this.reqNo}
				});
			}
		}

	}
</script>

<style scoped="scoped" lang="scss">
	.person_warp {
		margin-top: 34px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 14px 20px;
		.btn_warp {
			margin-left: 50px;
		}
	}
	.con_area {
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		padding: 14px 20px;
		margin-top: 14px;
	}
	.wxqrcode_warp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		//padding: 20px 0px 20px 20px;
		position: relative;

		.icon_colse {
			width: 26px;
			height: 26px;
			background: url(../../assets/icon/close.png) center no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}

		.wxqrcode_warp_left {
			display: flex;
			flex-direction: column;

			.wxqrcode_bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;

				.sys-desc {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					font-size: 14px;
					color: #1d1b1b;
				}

				.sys_icon {
					background: url(../../assets/icon/saoyisao.png) center no-repeat;
					background-size: 100%;
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
			}
		}

		.wxqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/pc_icon_phone-bg.png) center no-repeat;
			background-size: 80%;
		}
	}

	.lev-msg-tab-warp {
		/* border-bottom: 1px solid #E4E7ED;
		border-radius: 4px 4px 0 0; */
		box-sizing: border-box;
	}

	.lev-msg-tab-warp .tab-item {
		height: 40px;
		box-sizing: border-box;
		line-height: 40px;
		display: inline-block;
		list-style: none;
		font-size: 16px;
		font-weight: 800;
		color: rgb(43, 45, 46);
		text-align: left;
		position: relative;
		border-bottom: none;
		width: 82px;
		cursor: pointer;
	}

	.lev-msg-tab-warp .tab-item:not(:first-child) {
		border-left: none;
	}

	.lev-msg-tab-warp .active {

	}
	
	.lev-msg-tab-warp .active::after {
		content: "";
		display: block;
		width: 32px;
		height: 4px;
		background-color: #C88854;
		position: absolute;
		bottom: 0px;
		border-radius: 2px;
	}

	.container {
		width: 90%;
		margin: 0 auto;
		height: 100%;
	}

	.conts {
		margin-top: 20px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 20px 10px 50px 10px;
	}

	.cons_left {
		padding: 0px 10px 0px 0px;
		.carousel-warp {
			width: 100%;
			video {
				width: 100%;
				height: 100%;
			}
		
			img {
				width: 100%;
				height: 100%;
				object-fit:fill;
			}
			
			.imagewarp {
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit:contain;
				}
			}
		}
	}

	.tb-wrap {
		flex: 1;
		padding: 20px 20px 20px 10px;
	
		.ri_foot {
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;
			align-items: center;
		}
	}
	
	.btn_warp {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	
		.pop_btn_warp {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	
		.reset-btn {
			background-color: #8C939D;
			color: #FFFFFF;
			padding: 7px 20px;
			border-radius: 20px;
			font-size: 12px;
			cursor: pointer;
		}
	
		.nomal_btn {
			background-color: #FFFFFF;
			color: #C88854;
			padding: 6px 24px;
			border-radius: 20px;
			font-size: 14px;
			cursor: pointer;
			border: 1px solid;
		}
	
		.btn {
			background-color: #C88854;
			color: #FFFFFF;
			padding: 7px 20px;
			border-radius: 20px;
			font-size: 12px;
			cursor: pointer;
		}
	
		.rest_btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 20px;
			cursor: pointer;
	
			.icon_weixin {
				width: 30px;
				height: 30px;
				background: url(../../assets/icon/PAY-WEIXIN.png) no-repeat center;
				background-size: 100%;
				margin-right: 4px;
			}
	
			.icon_zfb {
				width: 30px;
				height: 30px;
				background: url(../../assets/icon/zfb.png) no-repeat center;
				background-size: 100%;
				margin-right: 4px;
			}
		}
	
		.rest_btn:hover {
			color: #c88854;
		}
	}

	.tb-wrap .ri-body {
		/* min-height: 300px; */
	}

	.tb-wrap .ri-body .rib-nail {
		text-align: left;
		word-break: break-all;
	}

	.tb-wrap .ri-body .icon-direct {
		background: url(../../assets/icon/to-right.png) center no-repeat;
		background-size: 40%;
		width: 70px;
		height: 20px;
	}

	.tb-wrap .ri-body .carry_from_place,
	.carry_to_place {
		font-weight: bold;
	}

	.tb-wrap .ri-body .mrgr {
		margin-right: 4px;
	}

	.tb-wrap .ri-body .rib-nail .lb {
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		color: #303133;
	}

	.tb-wrap .ri-body .rib-head {
		color: #c88854;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: 700;
		font-size: 16px;
	}

	.tb-wrap .ri-body .req-tag {
		border: 1px solid #bfbfbf;
		color: #777474;
		padding: 4px 12px;
		border-radius: 6px;
		font-size: 12px;
		margin-right: 8px;
	}

	.b-warp {
		display: flex;
		justify-content: flex-start;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 14px 20px;
	}

	.b-warp .bw-r {
		flex: 1;
	}

	.usr {
		display: flex;
		justify-content: center;
		align-items: flex-start;

		.sm,
		.db {
			display: flex;
			background-color: #8C939D;
			color: #FFFFFF;
			align-items: center;
			justify-content: center;
			font-size: 10px;
			padding: 4px 6px;
			border-radius: 6px;

			.icon-sm {
				background: url(../../assets/icon/sm.png) center no-repeat;
				background-size: 100%;
				height: 14px;
				width: 14px;
			}

			.icon-db {
				background: url(../../assets/icon/db.png) center no-repeat;
				background-size: 100%;
				height: 14px;
				width: 14px;
			}
		}

		.active {
			background-color: #c88854 !important;
		}

		.nick {
			font-size: 14px;
			font-weight: 600;
			color: #5e5a5a;

			.man {
				background: url(../../assets/icon/man.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
			}

			.woman {
				background: url(../../assets/icon/woman.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
			}
		}

		.avatar {
			width: 60px;
			height: 60px;
			border-radius: 40px;
			overflow: hidden;
			margin-right: 12px;
		}

		.avatar img {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}

	.nail-price {
		color: #FF0000;
		font-size: 18px;
		font-weight: bold;
	}

	.nail-price .currency_type {
		margin-right: 6px;
		font-size: 16px;
	}

	.amt-unit {
		font-size: 13px;
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
	}

	.amt-unit .unit-split {
		margin: 0 4px;
	}

	.op {
		font-size: 10px;
		color: #a09696;
		text-decoration: line-through;
		margin-left: 10px;
	}
</style>
