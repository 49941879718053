import { render, staticRenderFns } from "./publish.vue?vue&type=template&id=063a3bc8&scoped=true&"
import script from "./publish.vue?vue&type=script&lang=js&"
export * from "./publish.vue?vue&type=script&lang=js&"
import style0 from "./publish.vue?vue&type=style&index=0&id=063a3bc8&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063a3bc8",
  null
  
)

export default component.exports